import * as React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useSelector } from 'react-redux'
import { deleteMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import { useAlert } from 'react-alert'
import { loaderAction, logoutAction } from '../../services/actions'
import { toast } from '../Toast/Toast'

export default function DeleteAccount() {
  const { token } = useSelector(state => state.auth)
  const alert = useAlert()
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const signOut = () => {
    dispatch(logoutAction())
    window.opener.location.href = '/?logout=true'

    if (window.opener.progressWindow) {
      window.opener.progressWindow.close()
    }
    window.close()
    dispatch(logoutAction())
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()

    setOpen(false)
  }

  const dispatch = useDispatch()
  const deleteAccount = async () => {
    dispatch(loaderAction(true))

    const { data, error } = await deleteMethod(token, Api.deleteAccount, {})
    if (!error) {
      toast.success(data?.message)
      setTimeout(() => {
        signOut()
      }, 500)
    } else {
      toast.error(data?.message)
    }
    dispatch(loaderAction(false))
  }

  return (
    <div className="delete-account">
      <h6 className="pro-heading cursor" onClick={handleClickOpen}>
        Delete Account
      </h6>
      <p className="copyright ps-2">
        Copyright &copy; {new Date().getFullYear()}, DanteMedia, LLC
      </p>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Permanently Delete Account?'}
        </DialogTitle>
        <DialogContent>
          I understand that by deleting this account, I am permanently deleting
          all content associated with it. And that this account and its content
          cannot be recovered and will no longer be accessible on any device.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => deleteAccount()}
            className="cancel_logout_btn cancelBtn danger"
            variant="danger"
            color="contained"
          >
            I understand. Delete my Account
          </Button>{' '}
          <Button onClick={handleClose} className="logout_btn submitBtn">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
