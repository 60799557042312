import { useState } from 'react'
import { Checkbox, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  aiHandleClear,
  aiHandleGenerate,
  aiOnChange
} from '../../utils/aiCommonFunctions.utils'
import {
  SelectMenu,
  LanguageSelectMenu,
  StyleSelectMenu
} from '../commonComponent/SelectMenu'
import { ButtonLoader } from '../../Loader/ButtonLoader'
import ConfirmModal from '../Modal/ConfirmModal'
import { useSelector } from 'react-redux'
import { LeftRightPaneFolderTypes, limitAlert } from '../../utils/commonOptions'

const AssistantForm = ({
  values,
  setValues,
  btnLoader,
  wordCounts,
  setBtnLoader,
  setCounts,
  setAiContent,
  aContent,
  expand,
  setExpand
}) => {
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)
  const { type } = useSelector(state => state.songs)
  const [isOpen, setIsOpen] = useState(false)

  const handleGenerate = () => {
    setIsOpen(false)
    aiHandleGenerate(
      type,
      values,
      setBtnLoader,
      dispatch,
      token,
      setCounts,
      setAiContent
    )
  }

  const handleChange = panel => (event, newExpanded) => {
    setExpand(newExpanded ? panel : false)
  }

  return (
    <>
      {isOpen && (
        <ConfirmModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          confirmDelete={handleGenerate}
          message={
            wordCounts?.wordCount <= 250 && wordCounts?.wordCount >= 75
              ? limitAlert?.message
              : limitAlert?.below75
          }
          heading={
            wordCounts?.wordCount <= 250 && wordCounts?.wordCount >= 75
              ? limitAlert?.[250]
              : limitAlert?.[75]
          }
        />
      )}
      <Accordion
        className="ai-assistant-form-wrapper"
        expanded={expand === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1d-header"
          className="ai-collapse-header"
        >
          Use SP AI to generate lyrics
        </AccordionSummary>

        <div className="gray-box-ai">
          {type !== LeftRightPaneFolderTypes.NOTES && (
            <div className="row">
              <div className="col-lg-12">
                <div className="Theme-text">
                  <p className="m-0">
                    Theme <span>*</span>
                  </p>
                </div>
                <div className="theme-input-ai">
                  <TextField
                    type="text"
                    fullWidth
                    placeholder="e.g She drives me crazy"
                    name="theme"
                    onChange={e => aiOnChange(e, values, setValues)}
                    value={values?.theme}
                    inputProps={{ maxLength: 60 }}
                  />
                  <div className="count-no-ai-new">
                    <p className="m-0">{values?.theme?.length || 0}/60</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="Theme-text">
                <p className="m-0">Tone</p>
              </div>
              <div className="theme-input-ai">
                <TextField
                  type="text"
                  fullWidth
                  placeholder="e.g. romantic, witty"
                  name={'style'}
                  onChange={e => aiOnChange(e, values, setValues)}
                  inputProps={{
                    maxLength: 30
                  }}
                  value={
                    type !== LeftRightPaneFolderTypes.NOTES
                      ? values?.style
                      : values?.theme
                  }
                />
                <div className="count-no-ai-new">
                  <p className="m-0">
                    {values?.style?.length || 0}
                    /30
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="Theme-text">
                <p className="m-0">Genre</p>
              </div>
              <div className="theme-input-ai">
                <StyleSelectMenu
                  onChange={e => aiOnChange(e, values, setValues)}
                  value={values?.genre}
                  name="genre"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="Theme-text ">
                <p className="m-0">Verses</p>
              </div>
              <div className="theme-input-ai">
                <SelectMenu
                  name={'verses'}
                  value={values?.verses || 2}
                  onChange={e => aiOnChange(e, values, setValues)}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div className="Theme-text ">
                <p className="m-0">Language</p>
              </div>
              <div className="theme-input-ai">
                <LanguageSelectMenu
                  name={'lang'}
                  value={values?.lang || 'English'}
                  onChange={e => aiOnChange(e, values, setValues)}
                />
              </div>
            </div>
          </div>
          <div className={`ai-checkbox-wrapper `}>
            <div className=" pre-chorus-wrapper">
              <div className="d-flex align-items-center justify-content-start ps-0">
                <div className="custom-check-ai">
                  <Checkbox
                    checked={values?.intro}
                    name="intro"
                    onChange={e => aiOnChange(e, values, setValues)}
                  />
                  <p>Intro</p>
                </div>

                <div className="custom-check-ai">
                  <Checkbox
                    checked={values?.pre_chorus}
                    name="pre_chorus"
                    onChange={e => aiOnChange(e, values, setValues)}
                  />
                  <p>Pre-Chorus</p>
                </div>
                <div className="custom-check-ai">
                  <Checkbox
                    checked={values?.chorus}
                    name="chorus"
                    onChange={e => aiOnChange(e, values, setValues)}
                  />
                  <p>Chorus</p>
                </div>
                <div className="custom-check-ai">
                  <Checkbox
                    checked={values?.bridge}
                    name="bridge"
                    onChange={e => aiOnChange(e, values, setValues)}
                  />
                  <p>Bridge</p>
                </div>
                <div className="custom-check-ai">
                  <Checkbox
                    checked={values?.outro}
                    name="outro"
                    onChange={e => aiOnChange(e, values, setValues)}
                  />
                  <p>Outro</p>
                </div>
              </div>
            </div>

            <div className="pre-chorus-wrapper btns">
              <div className=" d-flex align-items-center justify-content-end mt-2">
                <div className="custom-check-ai  position-relative">
                  <button
                    onClick={() =>
                      aiHandleClear(setValues, dispatch, setAiContent)
                    }
                    disabled={btnLoader}
                    className="ms-2 clear-btn"
                  >
                    Clear
                  </button>
                </div>
                <div className="custom-check-ai Generate-Lyric-btn position-relative">
                  <button
                    onClick={() => {
                      if (!btnLoader) {
                        if (
                          wordCounts?.wordCount <= 250 &&
                          wordCounts?.wordCount >= 0
                        ) {
                          setIsOpen(true)
                        } else {
                          handleGenerate()
                        }
                      }
                    }}
                    disabled={btnLoader}
                  >
                    <img
                      className=""
                      src="assets/images/add-song-icon-new.svg"
                      alt=""
                    />{' '}
                    {aContent ? 'Regenerate' : 'Generate'} Lyric
                    {btnLoader && <ButtonLoader />}{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Accordion>
    </>
  )
}

export default AssistantForm
